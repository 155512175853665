@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-xl;
    @apply my-4;
  }
  h2 {
    @apply text-lg;
    @apply my-4;
  }
  p {
    @apply mb-4;
  }
  a {
    @apply text-sky-300;
  }
}

:root {
  font-size: 14px;
  color-scheme: dark;
  background-color: theme("colors.slate.900");
  color: theme("colors.white");

  --context-menu-item-font-size: 14px;
  --context-menu-item-padding: 0.5rem 1rem;
}

html {
  background-color: transparent;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*:focus-visible {
  outline-color: theme("colors.sky.500");
  outline-offset: -2px;
}
input[type="checkbox"]:focus-visible {
  outline-offset: 0;
}

a:focus-visible {
  color: theme("colors.sky.500");
  outline: none;
}

button {
  text-align: left;
}

input::placeholder {
  color: theme("colors.slate.500");
}

/* Powers the animated indeterminate progress bar */

.indeterminate {
  animation: indeterminate 1s infinite linear;
}

@keyframes indeterminate {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
